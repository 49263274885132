<template>
  <section>
    <detail-ticket-vue v-if="showView === 'detail'" :ticketIdModal="idTicket"></detail-ticket-vue>
    <div v-else-if="showView === 'creation'" class="d-flex justify-content-end p-2">
      <b-button v-b-modal.ModalFormCreateTicket variant="warning" > Crear Ticket </b-button>
    </div>
    <div v-else-if="showView === 'notAllow'">
      <b-alert variant="dark" show >
        <p class="text-center">{{ticketConfig.deny_message}}</p>
      </b-alert>
    </div>
    <form-create-ticket-vue :delivery_id="idTicket" :delivery_type="sourceForm" :activateOverlay="modalOverlay" @updateCreateTicket="createTicket"></form-create-ticket-vue>
  </section>
</template>
<script>
import BaseServices from '@/store/services/index'
import DetailTicketVue from '@/views/modules/tickets/components/Detail/DetailTicket.vue'
import FormCreateTicketVue from '@/views/modules/tickets/components/CreateTickets/FormCreateTicket.vue'
export default {
  props: ['ticketIdModal', 'source'],
  components: {DetailTicketVue, FormCreateTicketVue},
  data() {
    return {
      baseService: new BaseServices(this),
      ticketExist: false,
      idTicket: 0,
      sourceForm: '',
      modalOverlay: false,
      ticketConfig: {...this.defaultTicketConfig},
      defaultTicketConfig: {
        ticket_allowed: false,
        deny_message: 'Por el momento no se puede ver ni crear tickets',
        exist: false
      }
    }
  },
  mounted() {
    this.generateData()
  },
  computed: {
    user() {
      return this.$session.get('cas_user')
    },
    showView() {
      return this.ticketConfig.ticket_allowed ? this.ticketConfig.exist ? 'detail' : 'creation' : 'notAllow'
    }
  },
  methods: {
    existTicket() {
      this.resetValue()
      const params = {
      }
      const queryParams = {
        delivery_id: this.idTicket
      }
      this.baseService.callService('getTicketExist', queryParams, params)
        .then(response => {
          this.ticketExist = !!response.exist
          this.ticketConfig = {...response}
        })
    },
    createTicket(data) {
      this.modalOverlay = true
      const queryParams = {
        creator: {
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          email: this.user.email,
          role: this.user.role
        },
        current_type: {
          code: data.ticket_type_code.code,
          name: data.ticket_type_code.name
        },
        delivery: {
          id: this.idTicket
        },
        delivery_type: this.sourceForm ?? 'delivery',
        message: data.extra_fields.message ?? '',
        reading_status: {
          read_agent: true
        },
        extra_fields: {
          ...data.extra_fields.form
        }
      }
      this.baseService.callService('createTickets', queryParams, {})
        .then(resp => {
          this.$bvModal.hide('ModalFormCreateTicket')
          this.$success('Ticket creado exitosamente')
          this.existTicket()
        })
        .catch(err => {
          console.error(err)
          this.$alert(err)
        })
        .finally(() => {
          this.modalOverlay = false
        })
    },
    resetValue() {
      this.ticketExist = false
      this.ticketConfig = {...this.defaultTicketConfig}
    },
    generateData() {
      this.idTicket = this.$route.params.id ?? this.ticketIdModal
      this.sourceForm = this.$route.params.source ?? this.source ?? 'delivery'
      this.existTicket()
    }
  },
  watch: {
    '$route.params.id'() {
      this.generateData()
    }
  }
}
</script>