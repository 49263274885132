<template>
  <b-modal
    id="ModalFormCreateTicket"
    @show="show"
    @close="close"
    size="xl"
    hide-footer
    body-class="p-0"
    title="Creación de ticket"
    centered
  >
  <b-overlay :show="showOverlay" rounded="sm" opacity="1" variant="white">
    <b-card class="m-0">
      <form-render :form.sync="form" :fields="fields">
      </form-render>
      <extra-fields :extraField="objectExtraField"
        :masterData="masterData" :options="nameButton" @updateExtraField="updateExtraField">
      </extra-fields>
    </b-card>
  </b-overlay>
  </b-modal>
</template>
<script>
import BaseServices from '@/store/services/index'
import ExtraFields from '@/views/modules/tickets/components/ExtraFields/ExtraFields.vue'
export default {
  components: {ExtraFields},
  props: ['delivery_id', 'delivery_type', 'activateOverlay'],
  data() {
    return {
      baseService: new BaseServices(this),
      form: {},
      fields: [],
      objectExtraField: {},
      keyFormRender: 0,
      nameButton: 'Crear Ticket',
      masterData: {},
      showOverlay: false
    }
  },
  computed: {
    queryParamsMasterData() {
      return {
        'delivery_id': this.delivery_id,
        'ticket_exists': false,
        'delivery_type': this.delivery_type
      }
    },
    optionFields() {
      return this.masterData?.ticket_types?.map(el => ({...el, text: el.name})) ?? []
    }
  },
  watch: {
    'activateOverlay': {
      handler(value) {
        this.showOverlay = value
      }
    }
  },
  methods: {
    show() {
      this.showOverlay = true
      const params = {
        ticket_id: this.delivery_id
      }
      const arrServices = [
        { name: 'getTicketsMasterData', queryParams: this.queryParamsMasterData, params}
      ]
      this.baseService.callMultipleServices(arrServices, this.queryParamsMasterData, {})
        .then(response => {
          this.masterData = response.getTicketsMasterData.data
        })
        .finally(() => {
          this.setFields()
          this.showOverlay = false
        })
    },
    setFields() {
      this.fields = [
        {fieldType: 'FieldSelect', name: 'ticket_type', label: 'Asunto del ticket', options: this.optionFields,
          containerClass: 'col-sm-12 container-info', validation: 'required', change: this.addExtraFields,
          clearable: false}
      ]
    },
    addExtraFields(data) {
      this.objectExtraField = this.optionFields.find(el => el.code === this.form.ticket_type.code)
    },
    updateExtraField(data) {
      this.$emit('updateCreateTicket', data)
    },
    close() {
      this.form = {}
    }
  }
}
</script>